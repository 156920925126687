// the order of the tiles on the homepage would be based on this.

export const regionalDescription = [
    {
        category: "REGIONAL_MPO",
        verbose: "Metropolitan Planning Organization",
        description: "Twenty-one area-specific transportation statistical reports are provided. The areas include Metropolitan Planning Organization (MPO) regions, a consolidated rural area, a consolidated urban area, and a combined statewide region."
    },
    {
        category: "REGIONAL_REDC",
        verbose: "Regional Economic Development Council",
        description: "Ten area-specific transportation statistical reports are provided for Regional Economic Development Council Regions.",
    },
    {
        category: "REGIONAL_DOTR",
        verbose: "DOT Region",
        description: "Twelve area-specific transportation statistical reports are provided. The areas include eleven NYSDOT regions and a combined statewide region.",
    },
    {
        category: "REGIONAL_CDTC",
        verbose: "Capital District Transportation Committee",
        description: "County-level transportation statistics are provided for Capital District Transportation Committee (CDTC).",
    },
    {
        category: "REGIONAL_EMFAC",
        verbose: "Emission Factor Profile",
        description: "Emission factor profiles are provided for twenty-five areas in the state to be used in regional air quality modeling. The transportation statistics include regional percent of vehicle trips, vehicle miles traveled, and average trip length by time of day.",
    },
    {
        category: "REGINAL_COMP",
        verbose: "Comprehensive Study",
        description: "Examination of travel behavior and patterns by analyzing the 2017 NHTS data and comparison of those results to data from prior years to establish trends. Additionally, travel behaviors of NYS residents is compared to those of the rest of the nation as well as detailed geospatial analyses for regions within NYS to allow local planners and policymakers to conduct peer-comparisons."},

]

export const specialDescription = [
    {
        category: "SPECIAL_ELDERLY",
        verbose: "Elderly",
        description: "Analysis of travel patterns and characteristics of elderly population in New York State."
    },
    {
        category:"SPECIAL_LOWINCOME",
        verbose: "Low-income",
        description: "Analysis of travel patterns and characteristics of low-income population in New York State."
    },
    {
        category:"SPECIAL_RURAL",
        verbose: "Rural Areas",
        description: "Analysis of travel patterns and characteristics of population in rural areas of New York State."
    },
    {
        category:"SPECIAL_MILLENIALS",
        verbose: "Millennials",
        description: "Analysis of travel patterns and characteristics of millennials in New York State."
    },
    {
        category:"SPECIAL_0VEH",
        verbose: "Zero-vehicle",
        description: "Analysis of travel patterns and characteristics of population from zero-vehicle households in New York State."
    },
]

export const otherDescription = [
    {
        category: "OTHER_ALTFUEL",
        verbose: "Alternative Fuel Vehicle",
        description: "Analysis of alternative fuel vehicle usage and owner demographics in New York State."
    },
    {
        category: "OTHER_ODDATA",
        verbose: "Origin-Destination Data",
        description: "Reports and data sets produced using NextGen NHTS Origin-Destination data and other related data."
    },
    {
        category: "OTHER_FREIGHTDATA",
        verbose: "Freight Data",
        description: "Reports and data sets produced using freight-related data sets."
    },
    {
        category: "OTHER_PRESANDPUB",
        verbose: "Presentations and Publications",
        description: "Research outputs presented in professional conferences as well as published articles in technical journals."
    },
]


export const NHTSBasedStudies2009 = [
    {
        category: "REGIONAL_MPO_2009",
        verbose: "Metropolitan Planning Organization",
        description: "Study reports and data sets for NYS MPO regions."
    },
    {
        category: "SPECIAL_2009",
        verbose: "Special Population",
        description: "Study reports for special population groups, e.g., transit users and foreign-born populations."
    },
    {
        category: "OTHER_2009",
        verbose: "Other Studies",
        description: "Reports for emission factor profiles, trip chaining, and others."
    },
]



export const NHTSBasedStudies2001 = [
    {
        category: "REGIONAL_MPO_2001",
        verbose: "Metropolitan Planning Organization",
        description: "Study reports and data sets for NYS MPO regions."
    },
    {
        category: "SPECIAL_2001",
        verbose: "Special Population",
        description: "Study reports for special population groups, e.g., transit users and foreign-born populations."
    },
    {
        category: "OTHER_2001",
        verbose: "Other Studies",
        description: "Reports for long distance travel, views of travel, and others."
    },
]



// verbose (will be used in the homepage)
const regional_statistics_verbose = {};
const special_population_verbose = {};
const other_reports_verbose = {};
const nhts_based_studies_2009_verbose = {};
const nhts_based_studies_2001_verbose = {};


regionalDescription.forEach((description) => {
    other_reports_verbose[description.category] = description.verbose;
});
specialDescription.forEach((description) => {
    other_reports_verbose[description.category] = description.verbose;
});
otherDescription.forEach((description) => {
    other_reports_verbose[description.category] = description.verbose;
});
NHTSBasedStudies2009.forEach((description) => {
    nhts_based_studies_2009_verbose[description.category] = description.verbose;
});
NHTSBasedStudies2001.forEach((description) => {
    nhts_based_studies_2001_verbose[description.category] = description.verbose;
});








// Category
export const report_category_choices =  Object.keys(regional_statistics_verbose).concat(
                                        Object.keys(special_population_verbose),
                                        Object.keys(other_reports_verbose),
                                        Object.keys(nhts_based_studies_2009_verbose),
                                        Object.keys(nhts_based_studies_2001_verbose),
  );
  
export const report_category_verbose = Object.assign({},
    regional_statistics_verbose,
    special_population_verbose,
    other_reports_verbose,
    nhts_based_studies_2009_verbose,
    nhts_based_studies_2001_verbose,
);




