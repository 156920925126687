import React from 'react'
import { Link } from 'react-router-dom'
import { report_category_verbose, regionalDescription, specialDescription, otherDescription, NHTSBasedStudies2009, NHTSBasedStudies2001 } from './constants'
//import nyc_mpo from '../../assets/nyc_mpo.png'
//import special_population from '../../assets/special_population.png'
//import nyc_redc from '../../assets/nyc_redc.jpeg'
// import emission from '../../assets/emission.png'
//import other from '../../assets/other.jpeg'





function ReportTile({category, thumb, description}){
    return(
        <Link
            to={`/search/?category=${category}&no_search=true`} 
            className='flex text-black flex-col rounded shadow m-2 overflow-hidden hover:shadow-lg bg-white'>
            {/* <img className='flex-1 object-cover hover:scale-110 transition-all' width={"100%"} src={thumb}/> */}
            <span className='px-2 text-xl font-semibold py-2 border-t'>
                {report_category_verbose[category]||category}
            </span>
            <span className='px-2 pb-4'>{description}</span>
        </Link>
    )
}

export default function ReportTiles() {
  return (
    <div className='px-12 flex flex-col pt-8 portrait:px-6 portrait:pt-12 pb-12'>
        <span className='text-2xl font-semibold pb-4'>Regional Reports</span>
        <div className='grid grid-cols-4 portrait:grid-cols-1'>
            {
                regionalDescription?.map(d=>
                    <ReportTile {...d} key={d.title} />
                )
            }
        </div>

        <span className='text-2xl font-semibold pt-12 pb-4'>Special Population</span>
        <div className='grid grid-cols-4 portrait:grid-cols-1'>
            {
                specialDescription?.map(d=>
                    <ReportTile {...d} key={d.title} />
                )
            }
          </div>
          
        <span className='text-2xl font-semibold pt-12 pb-4'>Other Reports</span>
        <div className='grid grid-cols-4 portrait:grid-cols-1'>
            {
                otherDescription?.map(d=>
                    <ReportTile {...d} key={d.title} />
                )
            }
          </div>
          
          <span className='text-2xl font-semibold pt-12 pb-4'>2009 NHTS-Based Studies</span>
        <div className='grid grid-cols-4 portrait:grid-cols-1'>
            {
                NHTSBasedStudies2009?.map(d=>
                    <ReportTile {...d} key={d.title} />
                )
            }
        </div>

          
        <span className='text-2xl font-semibold pt-12 pb-4'>2001 NHTS-Based Studies</span>
        <div className='grid grid-cols-4 portrait:grid-cols-1'>
            {
                NHTSBasedStudies2001?.map(d=>
                    <ReportTile {...d} key={d.title} />
                )
            }
        </div>



    </div>
  )
}
